import React from 'react';
import { Box } from '@heycater/design-system';
import Image, { ImageProps } from 'next/image';
import styled from 'styled-components';

const TestimonialImage = (props: ImageProps) => {
  return (
    <Box position="relative" pr={1} height="100%" width="100%">
      <Image
        alt=""
        {...props}
        height={452}
        width={504}
        src="/artifacts/images/shared/testimonial/background-foods.webp"
        layout="responsive"
        quality={50}
      />
      <Image
        alt=""
        {...props}
        src="/artifacts/images/shared/testimonial/background-ellipse.webp"
        layout="fill"
      />
      <ProfileImageWrapper>
        <Image src={props.src} objectFit="cover" layout="fill" alt="" />
      </ProfileImageWrapper>
      <Image
        alt=""
        {...props}
        src="/artifacts/images/shared/testimonial/quote-icon.webp"
        layout="fill"
      />
    </Box>
  );
};

const ProfileImageWrapper = styled.div`
  border-radius: 1000px;
  aspect-ratio: 1/1;
  width: 62.5%;
  position: absolute;
  bottom: 7%;
  right: 0.5%;
  overflow: hidden;
`;

export default TestimonialImage;
