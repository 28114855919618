import React from 'react';
import { Box } from '@heycater/design-system';
import Image, { ImageProps } from 'next/image';
import styled from 'styled-components';

import bgFoods from '@images/shared/testimonial/bg-foods.png';
import bgShape from '@images/shared/testimonial/bg-shape.png';
import quoteImg from '@images/shared/testimonial/quote.png';

const TestimonialImageVariant = (props: ImageProps) => {
  return (
    <Wrapper position="relative" pr={1} height="100%" width="100%">
      <ImageArea>
        <ScaledBox $scale={0.83} left={'-6%'} top={'-6%'} position="relative">
          <Image alt="" {...props} src={bgFoods} />
        </ScaledBox>
      </ImageArea>

      <ImageArea>
        <ScaledBox
          $scale={0.75}
          right={'-10%'}
          bottom={'-6%'}
          position="relative"
        >
          <Image alt="" {...props} src={bgShape} />
        </ScaledBox>
      </ImageArea>

      <ImageArea>
        <ScaledBox
          $scale={0.63}
          right={'-10%'}
          bottom={'-7%'}
          position="relative"
        >
          <ProfileImageWrapper>
            <Image
              src={props.src}
              objectFit="cover"
              width="220px"
              height="200px"
              layout="responsive"
              alt=""
            />
          </ProfileImageWrapper>
        </ScaledBox>
      </ImageArea>

      <Box right={10} top={'25%'} position="absolute">
        <Image alt="" {...props} layout="fixed" src={quoteImg} />
      </Box>
    </Wrapper>
  );
};

const ScaledBox = styled(Box)<{ $scale: number }>`
  transform: scale(${({ $scale }) => $scale});
`;

const ImageArea = styled(Box)`
  grid-area: image;
  position: relative;
`;

const Wrapper = styled(Box)`
  display: grid;
  grid-template-areas: 'image';
`;

const ProfileImageWrapper = styled.div`
  mask-image: url(${bgShape.src});
  mask-size: cover;
  mask-position: center;

  ${({ theme }) => theme.breakpoints.up('md')} {
    mask-size: cover;
  }
`;

export default TestimonialImageVariant;
