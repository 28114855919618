import React from 'react';
import { Box, Typography } from '@heycater/design-system';
import Image from 'next/image';
import styled from 'styled-components';

import { EXPERIMENT } from 'lib/ablyft';
import { useVariationActive } from 'qualification/hooks/useVariationActive';
import TestimonialImage from 'shared/components/Testimonials/TestimonialImage';
import TestimonialImageVariant from 'shared/components/Testimonials/TestimonialImageVariant';
import { createSizes } from 'shared/helpers/createSizes';

export type TestimonialProps = {
  imageSrc: string;
  title: string;
  body: string;
  name: string;
  company: {
    name: string;
    image?: {
      src: string;
      height?: number;
      width?: number;
    };
    logoSrc?: string;
  };
};

const Testimonial = (props: TestimonialProps) => {
  const isHomepageRedesignActive = useVariationActive(
    EXPERIMENT.homepageRedesign.variations.variation
  );

  return (
    <Wrapper mx="auto">
      <Title mb={{ xs: 3, md: 5 }} textAlign={{ xs: 'center', md: 'start' }}>
        {props.title}
      </Title>
      <Body mb={3} textAlign={{ xs: 'center', md: 'start' }}>
        {'"'}
        {props.body}
        {'"'}
      </Body>
      <ImageArea mb={{ xs: 4, md: 0 }}>
        {isHomepageRedesignActive ? (
          <TestimonialImageVariant
            src={props.imageSrc}
            sizes={createSizes({ xs: '500px' })}
          />
        ) : (
          <TestimonialImage
            src={props.imageSrc}
            sizes={createSizes({ xs: '500px' })}
          />
        )}
      </ImageArea>
      <DetailsArea flexDirection={{ xs: 'column', md: 'row' }}>
        {props.company.image && (
          <CompanyLogoWrapper
            aspectRatio={`${props.company.image.width || 142}/${
              props.company.image.height || 42
            }`}
            height={35}
            position="relative"
          >
            <Image
              alt=""
              layout="responsive"
              src={props.company.image.src}
              height={props.company.image.height || 42}
              width={props.company.image.width || 142}
            />
          </CompanyLogoWrapper>
        )}
        <Box>
          <Typography fontWeight="600">{props.name}</Typography>
          <Typography>{props.company.name}</Typography>
        </Box>
      </DetailsArea>
    </Wrapper>
  );
};

const CompanyLogoWrapper = styled(Box)<{ aspectRatio: string }>`
  aspect-ratio: ${({ aspectRatio }) => aspectRatio};
`;

const Title = styled(Typography)`
  grid-area: title;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 160%;
  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 36px;
    justify-self: start;
    align-self: end;
    height: fit-content;
  }
`;

const Body = styled(Typography)`
  grid-area: body;
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 168%;
  max-width: 500px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    max-width: none;
  }
`;

const ImageArea = styled(Box)`
  grid-area: image;
  aspect-ratio: 504/452;
  width: 100%;
  max-width: 400px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    max-width: 500px;
  }
`;
const DetailsArea = styled(Box)`
  grid-area: details;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)}px;
  text-align: center;

  ${({ theme }) => theme.breakpoints.up('md')} {
    gap: ${({ theme }) => theme.spacing(4)}px;
    text-align: start;
    justify-self: start;
    align-self: start;
  }
`;

const Wrapper = styled(Box)`
  display: grid;
  width: 100%;
  grid-template-areas:
    'title'
    'image'
    'body'
    'details';

  justify-items: center;

  ${({ theme }) => theme.breakpoints.up('md')} {
    justify-items: center;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto;
    grid-column-gap: 1rem;
    grid-template-areas:
      'image title'
      'image body'
      'image details';
  }
`;

export default Testimonial;
