import React from 'react';
import { Box } from '@heycater/design-system';
import { ButtonBase } from '@material-ui/core';
import { ArrowForwardIos } from '@material-ui/icons';
import { useSwipeable } from 'react-swipeable';
import styled from 'styled-components';

import Dots from 'shared/components/Dots';
import Testimonial, {
  TestimonialProps,
} from 'shared/components/Testimonials/Testimonial';
import { usePagination } from 'shared/hooks/usePagination';

type Props = {
  items: TestimonialProps[];
};

const Testimonials = (props: Props) => {
  const testimonials = usePagination(props.items, { pageSize: 1 });
  const swipeHandlers = useSwipeable({
    onSwipedLeft: testimonials.next,
    onSwipedRight: testimonials.prev,
  });

  const testimonial = testimonials.currentPage[0];

  return (
    <Wrapper>
      <PaginationButton
        onClick={testimonials.prev}
        disabled={!testimonials.prevPage}
      >
        <ArrowBack />
      </PaginationButton>
      <div {...swipeHandlers}>
        <Testimonial
          body={testimonial.body}
          title={testimonial.title}
          company={testimonial.company}
          imageSrc={testimonial.imageSrc}
          name={testimonial.name}
        />
        <Box mt={2}>
          <Dots
            count={testimonials.pageCount}
            onClick={testimonials.setPageIndex}
            activeIndex={testimonials.pageIndex}
          />
        </Box>
      </div>
      <PaginationButton
        onClick={testimonials.next}
        disabled={!testimonials.nextPage}
      >
        <ArrowNext />
      </PaginationButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(5, 0, 4, 0)};
  ${({ theme }) => theme.breakpoints.up('md')} {
    gap: ${({ theme }) => theme.spacing(2)}px;
    align-items: center;
    display: flex;
  }
`;

const ArrowBack = styled(ArrowForwardIos)`
  transform: scaleX(-1) translateX(2px);
`;

const ArrowNext = styled(ArrowForwardIos)`
  transform: translateX(2px);
`;

const PaginationButton = styled(ButtonBase)`
  display: none;
  transition: background-color 0.2s ease-out;
  :disabled {
    opacity: 0.6;
  }
  height: auto;

  border-radius: 100px;
  padding: ${({ theme }) => theme.spacing(1, 1)};
  :hover {
    background-color: ${({ theme }) => theme.palette.grey[200]};
  }
  :active {
    background-color: ${({ theme }) => theme.palette.grey[300]};
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: block;
  }
`;

export default Testimonials;
